var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"white"},[_c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',{staticClass:"ma-0",staticStyle:{"min-height":"63px"},attrs:{"align":"center","justify":"space-between"}},[_c('v-col',{staticClass:"py-0 d-flex justify-space-between",attrs:{"cols":"auto"}},[(!_vm.headerPlaceholder && _vm.isAdmin)?_c('v-btn',{staticClass:"ml-4",attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();_vm.nav = !_vm.nav}}},[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("mdi-menu")])],1):_vm._e(),_c('a',{staticClass:"logo ml-6",attrs:{"href":"/"}},[_c('img',{attrs:{"src":require("@/assets/images/logo.svg"),"alt":"Fraktus"}})])],1),(!_vm.headerPlaceholder && _vm.isCustomer && _vm.isClientNotification)?_c('v-col',{staticClass:"py-0 mr-auto",attrs:{"cols":"auto"}},[_c('v-chip',{staticClass:"ma-0",attrs:{"color":"light-green lighten-5","text-color":"black"}},[_c('span',{staticClass:"py-1 px-2 mr-2 rounded-pill light-green darken-1 white--text"},[_vm._v(_vm._s(_vm._f("apiLocalize")('highlighted_text')))]),_vm._v(" "+_vm._s(_vm._f("apiLocalize")('general_text'))+" ")])],1):_vm._e(),_c('v-col',{staticClass:"py-0 pr-0 d-flex align-center",attrs:{"cols":"auto"}},[_c('v-select',{staticClass:"locale-selection",staticStyle:{"width":"120px"},attrs:{"solo":"","dense":"","hide-details":"","items":_vm.languageItems,"item-text":"text","item-value":"lang","item-color":"primary lighten-2"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var on = ref.on;
return [_c('v-divider',{staticClass:"mb-2"}),_c('v-list-item',{staticClass:"px-0"},[_c('img',{staticClass:"selection-logo",attrs:{"src":item.logo,"alt":"flag"}}),_c('v-list-item-title',[_vm._v(" "+_vm._s(item.text)+" ")]),(_vm.locale === item.lang)?_c('v-icon',{staticClass:"ml-4",attrs:{"size":"20","color":"primary"}},[_vm._v("mdi-check")]):_vm._e()],1)]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('img',{staticClass:"selection-logo",attrs:{"src":item.logo,"alt":"flag"}}),_c('span',{staticClass:"text-caption"},[_vm._v(_vm._s(item.text))])]}}]),model:{value:(_vm.locale),callback:function ($$v) {_vm.locale=$$v},expression:"locale"}}),(!_vm.headerPlaceholder && (_vm.isCompany || _vm.isCustomer))?_c('v-tooltip',{attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('a',{staticClass:"help-link mr-3",attrs:{"href":_vm.helpLink,"target":"_blank"}},[_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-help-circle")])],1)]}}],null,false,3247515356)},[_c('span',[_vm._v(_vm._s(_vm._f("localize")('help')))])]):_vm._e(),(!_vm.headerPlaceholder)?_c('v-menu',{attrs:{"bottom":"","left":"","offset-y":"","transition":"slide-y-transition","nudge-left":"10"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-avatar',_vm._g(_vm._b({staticClass:"ml-3",attrs:{"size":"63","tile":"","color":"primary"}},'v-avatar',attrs,false),on),[(_vm.logoutLoading)?_c('v-progress-circular',{attrs:{"size":"20","width":"2","color":"white","indeterminate":""}}):_c('v-icon',{attrs:{"size":"24","dark":""}},[_vm._v("mdi-account-circle")])],1)]}}],null,false,2705082412)},[_c('v-list',[(_vm.isCompany)?_c('v-list-item',{attrs:{"link":"","to":{name: "company-profile"}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm._f("localize")('companyProfile')))])],1):_vm._e(),(_vm.isCompany)?_c('v-list-item',{attrs:{"link":"","to":{name: "company-account"}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm._f("localize")('myAccount')))])],1):_vm._e(),(_vm.isCustomer)?_c('v-list-item',{attrs:{"link":"","to":{name: "account"}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm._f("localize")('myAccount')))])],1):_vm._e(),_c('v-list-item',{attrs:{"link":""},on:{"click":_vm.logOut}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm._f("localize")('logout')))])],1)],1)],1):_vm._e()],1)],1)],1),_c('v-divider'),_c('v-navigation-drawer',{attrs:{"width":"304","absolute":"","temporary":"","overlay-opacity":"0.32"},model:{value:(_vm.nav),callback:function ($$v) {_vm.nav=$$v},expression:"nav"}},[_c('v-list-item',{staticClass:"py-2 px-0"},[_c('v-btn',{staticClass:"ml-4",attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();_vm.nav = !_vm.nav}}},[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("mdi-menu")])],1),_c('a',{staticClass:"logo ml-6",attrs:{"href":"/"}},[_c('img',{attrs:{"src":require("@/assets/images/logo.svg"),"alt":"Fraktus"}})])],1),_c('v-divider'),_c('div',{staticClass:"subtitle-2 mt-4 ml-6 mb-3"},[_vm._v(_vm._s(_vm._f("localize")('adminArea')))]),_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{staticClass:"px-6",attrs:{"to":{name: 'admin-requests'},"link":"","color":"primary","ripple":false},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-icon',{staticClass:"mr-6"},[_c('v-icon',{attrs:{"color":active ? 'primary' : 'silver-chalice'}},[_vm._v("mdi-hand-coin")])],1),_c('v-list-item-content',[_c('div',{staticClass:"body-1 black--text"},[_vm._v(_vm._s(_vm._f("localize")('requests')))])])]}}])}),_c('v-list-item',{staticClass:"px-6",attrs:{"to":{name: 'price-settings'},"link":"","color":"primary","ripple":false},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-icon',{staticClass:"mr-6"},[_c('v-icon',{attrs:{"color":active ? 'primary' : 'silver-chalice'}},[_vm._v("mdi-currency-eur")])],1),_c('v-list-item-content',[_c('div',{staticClass:"body-1 black--text"},[_vm._v(_vm._s(_vm._f("localize")('marginSettings')))])])]}}])}),_c('v-list-item',{staticClass:"px-6",attrs:{"to":{name: 'users-relevancy'},"link":"","color":"primary","ripple":false},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-icon',{staticClass:"mr-6"},[_c('v-icon',{attrs:{"color":active ? 'primary' : 'silver-chalice'}},[_vm._v("mdi-cog")])],1),_c('v-list-item-content',[_c('div',{staticClass:"body-1 black--text"},[_vm._v(_vm._s(_vm._f("localize")('usersRelevancy')))])])]}}])}),_c('v-list-item',{staticClass:"px-6",attrs:{"to":{name: 'admin-companies'},"link":"","color":"primary","ripple":false},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-icon',{staticClass:"mr-6"},[_c('v-icon',{attrs:{"color":active ? 'primary' : 'silver-chalice'}},[_vm._v("mdi-truck-delivery")])],1),_c('v-list-item-content',[_c('div',{staticClass:"body-1 black--text"},[_vm._v(_vm._s(_vm._f("localize")('companies')))])])]}}])}),_c('v-list-item',{staticClass:"px-6",attrs:{"to":{name: 'clients'},"link":"","color":"primary","ripple":false},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-icon',{staticClass:"mr-6"},[_c('v-icon',{attrs:{"color":active ? 'primary' : 'silver-chalice'}},[_vm._v("mdi-account-multiple")])],1),_c('v-list-item-content',[_c('div',{staticClass:"body-1 black--text"},[_vm._v(_vm._s(_vm._f("localize")('clients')))])])]}}])}),_c('v-list-item',{staticClass:"px-6",attrs:{"to":"path","link":"","color":"primary","ripple":false,"disabled":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-icon',{staticClass:"mr-6"},[_c('v-icon',{staticClass:"grey--text text--lighten-1"},[_vm._v("mdi-finance")])],1),_c('v-list-item-content',[_c('div',{staticClass:"body-1 grey--text text--lighten-1"},[_vm._v(_vm._s(_vm._f("localize")('dashboard')))])])]}}])}),_c('v-list-group',{scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-icon',{staticClass:"mr-6 ml-2"},[_c('v-icon',{attrs:{"color":"silver-chalice"}},[_vm._v("mdi-tune")])],1),_c('v-list-item-content',[_c('div',{staticClass:"body-1 black--text"},[_vm._v(_vm._s(_vm._f("localize")('globalSettings')))])])]},proxy:true}])},[_c('v-list-item',{staticClass:"px-10",attrs:{"to":{name: 'global-settings'},"link":"","color":"primary","ripple":false}},[_c('v-list-item-content',[_c('div',{staticClass:"body-1 black--text"},[_vm._v(_vm._s(_vm._f("localize")('controls')))])])],1),_c('v-list-group',{attrs:{"sub-group":"","no-action":"","prepend-icon":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('div',{staticClass:"body-1 black--text px-4"},[_vm._v(_vm._s(_vm._f("localize")('textData')))])]),_c('v-list-item-icon',{staticClass:"v-list-group__header__prepend-icon"},[_c('v-icon',[_vm._v("$expand")])],1)]},proxy:true}])},[_c('v-list-item',{staticClass:"px-14",attrs:{"to":{name: 'general-text-data'},"link":"","color":"primary","ripple":false}},[_c('v-list-item-content',[_c('div',{staticClass:"body-1 black--text"},[_vm._v(_vm._s(_vm._f("localize")('general')))])])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }