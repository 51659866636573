<template>
  <v-overlay v-if="isLoading" :opacity="1" color="alabaster">
    <v-progress-linear indeterminate style="width: 100vw" color="primary" :height="2" :rounded="true"/>
  </v-overlay>
  <div v-else class="main-wrapper">
    <Header ref="header" id="header"/>
    <div class="flex-grow-1">
      <router-view/>
    </div>
    <Footer/>
  </div>
</template>

<script>
  import Header from '@/components/common/Header';
  import Footer from '@/components/common/Footer';
  import api from '@/api/api';

  let checkToken;

  export default {
    name: 'Home',
    components: {Footer, Header},

    data() {
      return {
        settingsLoading: false,
        localesLoading: false,
        checkLoading: false
      };
    },

    async beforeMount() {
      if (this.isAdmin || this.isCustomer) {
        this.getAPISettings();
        this.getAPILocales();
      }
      this.checkLoading = true;
      await this.initCheckToken();
      checkToken = setInterval(async () => {
        await this.initCheckToken();
      }, 60000);
    },

    beforeDestroy() {
      clearInterval(checkToken);
    },

    computed: {
      isCustomer() {
        return this.$store.getters.isCustomer;
      },
      isAdmin() {
        return this.$store.getters.isAdmin;
      },
      isLoading() {
        return this.settingsLoading || this.localesLoading || this.checkLoading
      }
    },

    methods: {
      async initCheckToken() {
        try {
          await api.checkToken();
          this.checkLoading = false;
        } catch (e) {}
      },
      async getAPILocales() {
        try {
          this.localesLoading = true;
          let notificationsData = await api.getNotifications();
          this.$store.commit('changeApiLocales', notificationsData.data)
        } finally {
          this.localesLoading = false;
        }
      },
      async getAPISettings() {
        try {
          this.settingsLoading = true;
          let settingsData = await api.getGlobalSettings();
          this.$store.commit('changeApiSettings', settingsData.data)
        } finally {
          this.settingsLoading = false;
        }
      }
    }
  };
</script>
