<template>
  <div style="color: white" class="primary py-4">
    <v-container fluid class="py-0 px-6">&copy; Copyright 2019 - All Rights Reserved</v-container>
  </div>
</template>

<script>
  export default {
    name: 'Footer',
  };
</script>
